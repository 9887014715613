import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: '/',
        component: () =>
            import('../views/mainIndex.vue')
    },
    {
        path: '/certificate',
        name: '/certificate',
        component: () =>
            import('../views/certificate.vue')
    },

]
const orginalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return (orginalPush.call(this, location)).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router