import request from "./request.js";

/**
 * get请求封装
 * @param {String} url 请求地址
 * @param {Object} params 请求参数
 * @param {showSuccessMsg,showFailMsg,showLoading} myConfig 请求状态显示
 */
export const get = (url, params = {}, myConfig = {}) => {

        return request({
            url: url,
            method: 'get',
            params,
            myConfig: {
                showSuccessMsg: false,
                showFailMsg: true,
                showLoading: true,
                ...myConfig
            }
        })
    }
    /**
     * post 请求封装
     * @param {String} url 请求地址
     * @param {*} data 请求参数
     * @param {showSuccessMsg,showFailMsg,showLoading} myConfig 请求状态显示
     */
export const post = (url, data, myConfig = {}) => {

    return request({
        url: url,
        method: 'post',
        data,
        myConfig: {
            showSuccessMsg: true,
            showFailMsg: true,
            showLoading: true,
            ...myConfig
        }
    })
}

/**
 * post 文件上传请求参数
 * @param {String} url 请求地址
 * @param {Object} data 请求参数
 * @param {Function} uploadProgress 进度条回调方法
 * @param {showSuccessMsg,showFailMsg,showLoading} myConfig 请求状态显示
 */
export const upload = (url, data, uploadProgress = () => {}, myConfig = {}) => {
        return request({
            url: url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data,
            onUploadProgress: uploadProgress,
            myConfig: {
                showSuccessMsg: true,
                showFailMsg: false,
                showLoading: false,
                ...myConfig
            }
        })
    }
    /**
     * get文件下载封装
     * @param {String} url 请求地址
     * @param {*} data 请求参数
     * @param {showSuccessMsg,showFailMsg,showLoading} myConfig 请求状态显示
     */
export const getFile = (url, data, myConfig = {}) => {
    return request({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': 'application/vnd.ms-excel; charset=utf8'
        },
        params: data,
        responseType: 'blob',
        myConfig: {
            showSuccessMsg: true,
            showFailMsg: true,
            showLoading: true,
            ...myConfig
        }
    })
}

/**
 * Post文件下载封装
 * @param {String} url 请求地址
 * @param {*} data 请求参数
 * @param {showSuccessMsg,showFailMsg,showLoading} myConfig 请求状态显示
 */
export const getFileS = (url, data, myConfig = {}) => {
    return request({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/vnd.ms-excel; charset=utf8'
        },
        params: data,
        responseType: 'blob',
        myConfig: {
            showSuccessMsg: true,
            showFailMsg: true,
            showLoading: true,
            ...myConfig
        }
    })
}

// 设计导入
export const updateFile = (params, myConfig) => {
        return request.post(`${'http://operator.ecoremedia.net'}/advertisement/design/import`, params, myConfig = {
            myConfig: {
                showSuccessMsg: true,
                showFailMsg: true,
                showLoading: true,
                ...myConfig
            }
        })
    }
    // 剪辑导入
export const Editingtheimport = (params, myConfig) => {
    return request.post(`${'http://operator.ecoremedia.net'}/clip/import`, params, myConfig = {
        myConfig: {
            showSuccessMsg: true,
            showFailMsg: true,
            showLoading: true,
            ...myConfig
        }
    })
}